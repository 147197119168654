import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import Link from './link'
import styled from 'styled-components'

let BtnStyle = styled.div`
  .btn {
    border-radius: 0;
    text-align: center;
    margin-bottom: 1rem;
    font: normal  22px / 24px "Kadwa", Helvetica, Arial, Verdana, sans-serif !important;
    text-transform: uppercase;
  }
  &.hide-image {
    img, .gatsby-image-wrapper {
      display: none;
    }
  }
`

const DynamicSidebar = ({...props}) => {
  const data = useStaticQuery(graphql`
    query WorkSeekersSidebar {
      allWordpressWpApiMenusMenusItems(filter: {name: {eq: "Sidebar Mechanics"}}) {
        group(field: id) {
          nodes {
            name
            items {
              title
              url
              wordpress_parent
              wordpress_children {
                title
                url
                wordpress_parent
                wordpress_children {
                  title
                  url
                  wordpress_parent
                }
              }
            }
          }
        }
      }
    }
  `)
  let items = data.allWordpressWpApiMenusMenusItems.group[0]?.nodes[0]?.items
  return (
    <BtnStyle {...props}>
      {items && items.map(item => (
        <Link to={item.url} className="btn btn-secondary btn-block btn-lg">
          {item.title}
        </Link>
      ))}
    </BtnStyle>
  )
}

export {DynamicSidebar}
