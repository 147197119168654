import styled from 'styled-components'

let PageStyling = styled.div`
  position: relative;
  overflow: hidden;
  figure {
    max-width: 100%;
    height: auto;
  }
  img {
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
    margin-right: 2rem;
    object-fit: contain;
    &.alignleft {
      display: inline-block;
      float: left;
    }
  }
  p, ul, ol, div {
    color: #89969E;
  }
  h1, h2, h3, h4 {
    color: #375893;
    text-transform: uppercase;
  }
  h3 {
    font: normal 24px / 26px "Kadwa", Helvetica, Arial, Verdana, sans-serif;
  }
  h2 {
    border-bottom: 1px solid #375893;
    margin-bottom: 1rem;
  }
  iframe {
    width: 100% !important;
    @media ${props => props.theme.breakpoints.smDown} {
      height: 300px;
    }
  }
  .wp-block-embed__wrapper {
    --video--width: 1296;
    --video--height: 540;

    position: relative;
    padding-bottom: calc(var(--video--height) / var(--video--width) * 100%); /* 41.66666667% */
    overflow: hidden;
    max-width: 100%;
    background: black;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`

export default PageStyling
