import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import PageStyling from './pageStyling'
import styled from 'styled-components'

let StyledHeading = styled.h2`
  color: #375893;
  font: normal bold 42px / 46px "Kadwa", Helvetica, Arial, Verdana, sans-serif;
  text-transform: uppercase;
  padding-bottom: 5px;
  border-bottom: 1px solid #375893;
  margin-bottom: 2rem;
  display: flex;
  &> div {
    margin-left: auto;
    img {
      max-height: 4rem;
    }
  }
`

let Img = styled.img`
  max-width: 30vw;
  @media ${props => props.theme.breakpoints.md} {
    max-width: unset;
  }
`

export default ({content, Sidebar, page, location, children, subtitle, showSideBarTop = false, custom_menu_items}) => (
  <Container className="py-4">
    {subtitle ? <StyledHeading className="text-primary">
      {subtitle}
      {(page.featured_media && page.path.startsWith('/mechanics/jobs/')) ?
        <div>
          <Img src={page.featured_media.source_url} className="d-none d-sm-inline"/>
        </div>
      : ''}
    </StyledHeading> : ''}
    {(Sidebar && showSideBarTop) ?
      <Sidebar
        page={page}
        location={location}
        className="d-sm-none hide-image"
        custom_menu_items={custom_menu_items}
      /> : ''}
    <Row>
      <Col lg={Sidebar ? 8 : 12} className="mb-4">
        {children ? <PageStyling>{children}</PageStyling> : <PageStyling dangerouslySetInnerHTML={{ __html: content }} />}
      </Col>
      {Sidebar ? <Col lg={4}>
        <Sidebar page={page} location={location} custom_menu_items={custom_menu_items}/>
      </Col> : ''}
    </Row>
  </Container>
)
