import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import Link from './link'
import styled from 'styled-components'

let BtnStyle = styled.div`
  .btn {
    border-radius: 0;
    text-align: center;
    margin-bottom: 1rem;
    font: normal  22px / 24px "Kadwa", Helvetica, Arial, Verdana, sans-serif !important;
    text-transform: uppercase;
  }
  &.hide-image {
    img, .gatsby-image-wrapper {
      display: none;
    }
  }
`

const CustomMenuItems = ({custom_menu_items = [], test, ...props}) => {
  let links = {
    "HOW WE WORK": '/job-seekers/how-we-work/',
    "INFORMATION ABOUT JOBS": '/job-seekers/jobs/',
    "BROWSE OUR JOBS": '/mechanics/jobs/',
    "ARRANGE A CHAT": `https://app.techsonthemove.com.au/get-started/candidate`,
    "DOWNLOAD OUR EBOOK": '/download-ebook/',
    "MORE ABOUT MIGRATION": '/job-seekers/migration-services/'
  }
  let processed_menu_items = custom_menu_items.map(cmi => {
    return {
      name: cmi,
      url: links[cmi]
    }
  })
  return (
    <BtnStyle {...props}>
      {processed_menu_items.map(cmi => (
        <Link to={cmi.url} className="btn btn-secondary btn-block btn-lg">
          {cmi.name}
        </Link>
      ))}
    </BtnStyle>
  )
}

let SidebarOne = ({location, page, ...props}) => {
  const data = useStaticQuery(graphql`
    query {
      map: file(relativePath: { eq: "map.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <BtnStyle {...props}>
      <Link to="/mechanics/register-with-us-candidate/" className="btn btn-success btn-block btn-lg">
        Arrange a Chat
      </Link>
      <Img fluid={data.map.childImageSharp.fluid} className="mt-4"/>
    </BtnStyle>
  )
}

const SidebarTwo = ({location, page, ...props}) => {
  return (
    <BtnStyle {...props}>
      {location && location.pathname == '/mechanics/how-we-work/' ? '' : <Link to='/mechanics/how-we-work/' className="btn btn-secondary btn-block btn-lg">
        HOW WE WORK
      </Link>}
      <Link to='/mechanics/jobs/' className="btn btn-secondary btn-block btn-lg">
        INFORMATION ABOUT JOBS
      </Link>
    </BtnStyle>
  )
}

const Blog = ({location, page, ...props}) => {
  return (
    <BtnStyle {...props}>
      {location && location.pathname == '/mechanics/how-we-work/' ? '' : <Link to='/mechanics/how-we-work/' className="btn btn-secondary btn-block btn-lg">
        HOW WE WORK
      </Link>}
      <Link to='/mechanics/jobs/' className="btn btn-secondary btn-block btn-lg">
        INFORMATION ABOUT JOBS
      </Link>
      {location && location.pathname == '/download-ebook/' ? <Link to='/mechanics/migration-services/' className="btn btn-secondary btn-block btn-lg">
        MORE ABOUT MIGRATION
      </Link> : <Link to='/download-ebook/' className="btn btn-secondary btn-block btn-lg">
        DOWNLOAD OUR EBOOK
      </Link>}
    </BtnStyle>
  )
}

const ArrangeChat = ({location, page, ...props}) => {
  let app_url = `https://app.techsonthemove.com.au/get-started/candidate`
  return (
    <BtnStyle {...props}>
      <a href={app_url} className="btn btn-success btn-block btn-lg">
        Arrange a Chat
      </a>
    </BtnStyle>
  )
}

const RegisterWithUs = ({location, page, ...props}) => {
  let app_url = `/mechanics/register-with-us-candidate/`
  return (
    <BtnStyle {...props}>
      <Link to={app_url} className="btn btn-success btn-block btn-lg">
        REGISTER WITH US
      </Link>
    </BtnStyle>
  )
}

const SidebarThree = ({location, page, ...props}) => {
  let app_url = `https://app.techsonthemove.com.au/users/sign_in`
  return (
    <BtnStyle {...props}>
      <Link to={app_url} className="btn btn-secondary btn-block btn-lg">
        Log In
      </Link>
      <Link to='/workshops/about/' className="btn btn-secondary btn-block btn-lg">
        About Us
      </Link>
    </BtnStyle>
  )
}

export {SidebarOne, SidebarTwo, SidebarThree, ArrangeChat, Blog, RegisterWithUs, CustomMenuItems}
