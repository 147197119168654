import React from "react"
import {Container} from 'reactstrap'
import styled from 'styled-components'

let StyledHeader = styled.div`
  @media ${props => props.theme.breakpoints.smDown} {
    text-align: center;
    h1 {
      font-size: 1.7rem;
    }
  }
  @media ${props => props.theme.breakpoints.md} {
    text-align: left;
  }
`

export default ({title}) => (
  <StyledHeader className="page-header mb-4">
    <Container className="py-4 py-md-5">
      <h1 dangerouslySetInnerHTML={{ __html: title }} style={{color: 'white', textTransform: 'uppercase'}} className="mb-0"/>
    </Container>
  </StyledHeader>
)
