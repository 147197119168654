import React, { useState } from "react"
import styled from 'styled-components'
import { FaPlay } from "react-icons/fa";

let ImageContainer = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`

let PlayContainer = styled.div`
  position: absolute;
  z-index: 100;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  & > div {
    background: rgb(255, 0, 0);
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    @media ${props => props.theme.breakpoints.md} {
      padding: 1rem 2rem;
    }
  }
`

export default ({video, imgStyle = {maxWidth: '1100px'}, image}) => {
  const [playingVideo, playVideo] = useState(false);
  return (
    <>
      {playingVideo ?
        video :
        <ImageContainer onClick={() => playVideo(true)} className="video-toggle">
          <div>
            <PlayContainer>
              <div>
                <FaPlay size={25}/>
              </div>
            </PlayContainer>
          </div>
          {image ? image : video}
        </ImageContainer>
      }
    </>
  )
}
