import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import PageHeader from '../components/pageHeader'
import {SidebarOne, ArrangeChat, RegisterWithUs, CustomMenuItems} from '../components/sidebar'
import Slider from '../components/slider'
import PageTemplate from '../components/pageTemplate'
import {DynamicSidebar} from '../components/dynamicSidebar'
import {WorkProvidersSidebar} from '../components/workProvidersSidebar'

export default ({ data, location }) => {
  const {acf, title, excerpt, content} = data.wordpressPage
  let path = location.pathname
  let ArrangeChatOnly = ([
    '/mechanics/register-with-us-candidate/'
  ].indexOf(path) >= 0) || acf.sidebar === 'Arrange A Chat'
  let registerOnly = acf.sidebar === 'Register With Us'
  let noSidebar = acf.sidebar === 'No Sidebar'
  let dynamicSidebar = acf.sidebar === 'Dynamic Sidebar'
  let employer = path.startsWith('/workshops')
  let employee = ['/mechanics/jobs/', '/job-seekers/jobs/'].some(word => path.startsWith(word));
  let Sidebar
  let showSideBarTop = false
  switch(true) {
    case acf.custom_menu_items?.length > 0:
      Sidebar = CustomMenuItems
      break;
    case registerOnly:
      Sidebar = RegisterWithUs
      break;
    case employee:
      Sidebar = SidebarOne
      showSideBarTop = true
      break;
    case ArrangeChatOnly:
      Sidebar = ArrangeChat
      break;
    case employer:
      Sidebar = WorkProvidersSidebar
      break;
    case dynamicSidebar:
      Sidebar = DynamicSidebar
      break;
    case noSidebar:
      Sidebar = false
      break;
    default:
      Sidebar = DynamicSidebar
  }
  return (
    <Layout title={title} description={excerpt.replace(/(<([^>]+)>)/ig,"")}>
      {['/job-seekers/how-we-work/', '/mechanics/how-we-work/'].includes(location.pathname) ? <Slider/> : <PageHeader title={title}/>}
      {data.wordpressPage && <PageTemplate
        content={content}
        Sidebar={Sidebar}
        page={data.wordpressPage}
        location={location}
        subtitle={acf.subtitle}
        custom_menu_items={acf.custom_menu_items}
        showSideBarTop={showSideBarTop}
      />}
    </Layout>
  )
}

export const query = graphql`
  query($wordpress_id: Int) {
    wordpressPage(wordpress_id: {eq: $wordpress_id}) {
      title
      slug
      content
      wordpress_id
      excerpt
      path
      parent_element {
        wordpress_id
      }
      acf {
        subtitle
        custom_menu_items
        sidebar
      }
      featured_media {
        source_url
      }
    }
  }
`
