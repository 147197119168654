import React from "react";
import { graphql } from 'gatsby'
import { useStaticQuery} from "gatsby"
import Slider from "react-slick";
import styled from 'styled-components'
import slide1 from '../images/slides/slide1.jpg'
import slide2 from '../images/slides/slide2.jpg'
import slide3 from '../images/slides/slide3.jpg'
import slide4 from '../images/slides/slide4.jpg'
import VideoToggle from './videoToggle'
import bg from '../images/welcome-bg.png'
import {Container} from 'reactstrap'

let Img = styled.img`
  width: 100%;
`

let Slide = ({img}) => (
  <div>
    <Img src={img}/>
  </div>
)

var settings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 4000,
  slidesToShow: 1,
  slidesToScroll: 1
};

let HomeVideo = styled.div`
  background: url(${bg});
  color: white;
  height: 100%;
  margin: auto;
  h3, h2 {
    color: white;
  }
  h2 {
    font-size: 0.8rem;
    @media ${props => props.theme.breakpoints.md} {
      font-size: 2rem;
    }
  }
`

let StyledSlider = styled(props => <Slider {...props}/>)`
  .slick-arrow {
    z-index: 10000;
    &:before {
      font-size: 30px;
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
    }
  }
  .slick-arrow.slick-prev {
    left: 20px;
    &:before {
      left: 0;
    }
  }
  .slick-arrow.slick-next {
    right: 20px;
    &:before {
      right: 0;
    }
  }
`

export default ({
  video = "http://app-uk.techsonthemove.com/wp-content/uploads/2021/12/our-candidate-pool.mp4",
  title = "HOW WE WORK",
  imgSrc
}) => {
  const data = useStaticQuery(graphql`
    query SliderQuery {
      file(name: {eq: "totm-thumb"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
    }
  `)
  return (
    <StyledSlider {...settings}>
      <HomeVideo className="text-center">
        <Container className="d-flex py-2 py-lg-4 flex-column h-100">
          <h2 className="text-center text-white mb-2">{title}</h2>
          <div className="position-relative d-flex justify-content-center" style={{flex: 1}}>
            <VideoToggle
              video={
                <video
                  controls
                  height="100%"
                  src={video}
                  style={{position: 'absolute', maxWidth: '100%'}}
                />
              }
              image={
                <img
                  src={imgSrc ? imgSrc : data.file.childImageSharp.fluid.src}
                  style={{
                    width: 'auto',
                    height: '100%',
                    maxHeight: '100%',
                    objectFit: 'cover',
                    position: 'absolute'
                  }}
                />
              }
              imgStyle={{maxWidth: '500px'}}
            />
          </div>
        </Container>
      </HomeVideo>
      <Slide img={slide1}/>
      <Slide img={slide2}/>
      <Slide img={slide3}/>
      <Slide img={slide4}/>
      <Slide img="https://app-au.techsonthemove.com/wp-content/uploads/2018/11/tech3.jpg"/>
      <Slide img="https://app-au.techsonthemove.com/wp-content/uploads/2018/11/cliff-jumping.jpg"/>
      <Slide img="https://app-au.techsonthemove.com/wp-content/uploads/2018/11/tech.jpg"/>
      <Slide img="https://app-au.techsonthemove.com/wp-content/uploads/2018/11/tech2.jpg"/>
      <Slide img="https://app-au.techsonthemove.com/wp-content/uploads/2018/11/surfing.jpg"/>
      <Slide img="https://app-au.techsonthemove.com/wp-content/uploads/2018/11/tech4.jpg"/>
    </StyledSlider>
  )
}
